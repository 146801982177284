<template>
  <v-card dense>
    <v-img :src="barcodeImageUrl" />
    <v-card-title>{{ $t('barcode') }}</v-card-title>
    <v-card-subtitle>{{ $t('aUniqueBarcodeToReferenceThisItem') }}</v-card-subtitle>
    <v-card-actions>
      <v-btn
        small
        text
        color="primary"
        download="barcode.png"
        :href="barcodeImageUrl"
      >
        {{ $t('download') }}
      </v-btn>
      <v-spacer />
      <v-btn
        small
        text
        color="primary"
        @click="printImage(barcodeImageUrl)"
      >
        {{ $t('print') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'ItemBarCodeCard',
    props: {
      item: {
        type: Object, 
        default: () => {}
      }
    },
    computed: {
        barcodeImageUrl: function() {
            return 'https://barcode.tec-it.com/barcode.ashx?data=' + this.item.barcode + '&code=Code128&dpi=96&dataseparator='
        },
    },
    methods: {
        imagetoPrint: function(source) {
            return "<html><head><scri"+"pt>function step1(){\n" +
                    "setTimeout('step2()', 10);}\n" +
                    "function step2(){window.print();window.close()}\n" +
                    "</scri" + "pt></head><body onload='step1()'>\n" +
                    "<img src='" + source + "' /></body></html>"
        },
        printImage: function(source) {
            var Pagelink = "about:blank"
            var pwa = window.open(Pagelink, "_new")
            pwa.document.open()
            pwa.document.write(this.imagetoPrint(source))
            pwa.document.close()
        }
    }
}
</script>